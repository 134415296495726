import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import routesMap from '../../Routes';
import Dots from './Dots';
import { Banner, Title1, Title2 } from './homeStyledComponents';
import { Button } from '../../components/Home/v2/StyledComponents';
import { colors, margins, fontSizes, mobileThresholdPixels, ImgCover } from '../styledComponents';
import Assurance3 from '../../assets/assurance-3.jpg';
import photoMan from '../../assets/photoMan.jpg';
import iconQuestion from '../../assets/question.png';

const Container = styled.div`
  padding: 0px 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 5px;
  }
`;

const Column = styled.div`
  margin: 20px 20px 80px;
  display: flex;
  flex-direction: column;
  text-align: justify;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 5px 15px;
    max-width: 100%;
  }
`;

const P = styled.p`
  color: ${colors.navy};
  ${props => props.center ? 'text-align: center;' : ''}
`;

const Title3 = styled.h3`
  color: ${colors.navy};
  font-size: 24px;
  font-weight: 300;
  margin: 0px;
  text-align: left;
`;

const BannerContainer = styled.div`
  margin-left: -100px;
  position: absolute;
  bottom: 40px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: inherit;
    position: static;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled.div`
  padding: 0px 0px 50px;
  align-self: center;

  @media (max-width: ${mobileThresholdPixels}) {
    display: block;
    padding: 0px;
  }
`;

const CallToAction = styled.div`
  padding: ${margins.l} 0px;
  align-self: center;
  color: ${colors.navy};
  font-size: ${fontSizes.xl};
  font-weight: 300;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const Icon = styled.img`
  height: 80px;
  width: 80px;
  margin: 20px;

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover{
      transform: scale(1.1) rotate(-7deg);
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    height: auto;
    width: 60px;
  }
`;

const questionsSections = [
  {
    name: 'Le service Tilli',
    questions: [
      {
        question: 'Tilli c‘est quoi ?',
        // eslint-disable-next-line max-len
        answer: `C'est un service de retouches et de transformations de vêtement à domicile ! Grâce à une équipe de Tillistes ultra qualifiés, nous redonnons vie à votre dressing.
        `,
      },
      {
        question: 'Pourquoi utiliser Tilli ?',
        answer: `Vous gagner du temps puisque le service est à domicile.
        Vous accédez à des retouches dont la qualité n'est jamais remise en question.
        Vous vivez une expérience humaine riche durant votre rendez-vous avec votre Tilliste.
        Et pour finir, grâce à l'oeil stylistique de nos Tillistes, vous consommez vos vêtements
        de façon plus durable. Et le tout, au même prix que la retoucherie de quartier.
        `,
      },
      {
        question: 'C‘est quoi un/une Tilliste ?',
        answer: `Un Tilliste, c'est un couturier multi expertise, c'est-à-dire aussi styliste
        et modéliste, pour vous faire bénéficier d'un service complet de qualité chez vous.
        `,
      },
      {
        question: 'Tilli c‘est uniquement de la retouche de vêtements ?',
        answer: `Non ! Nous retouchons aussi votre déco : rideaux et coussins !
        Tout ce qui nécessite de la couture, on le fait !
        `,
      },
      {
        question: 'Quelles sont les villes couvertes par le service de retouches Tilli ?',
        answers: [`Pour le moment, nos Tillistes interviennent à Paris et villes proches
          notamment sud et ouest, Marseille, Lyon, Aix-en-Provence et Metz.
        `],
      },
      {
        question: 'Quand pourrais-je accéder au service de retouches Tilli hors des villes précédemment citées ?',
        answer: `Bientôt ! On travaille d'arrache-pied pour ouvrir d'autres grandes villes de France dans l'année :)
        `,
      },
    ],
  },
  {
    name: 'Votre commande',
    questions: [
      {
        question: 'Comment puis-je passer commande d‘une retouche Tilli ?',
        answer: `C'est très simple, il vous suffit de cliquer sur le bouton "réserver" en haut de ce site
        ou de télécharger notre application mobile iPhone ou Android.
        `,
      },
      {
        // eslint-disable-next-line max-len
        question: 'Quelle différence entre "réserver en 3 clics" et "faire mon devis" lors de ma réservation en ligne ?',
        answer: `En choisissant "réserver en 3 clics", votre expérience de commande est plus rapide.
        Vous prenez rendez-vous directement avec votre Tilliste en choisissant le jour et l'horaire
        de votre choix, sans devis. A contrario, en choisissant "faire mon devis", vous précisez
        les vêtements et les retouches associées afin d'avoir une estimation claire du prix total.
        `,
      },
      {
        question: 'Je ne sais pas quelles retouches choisir :',
        answer: `Cette estimation nous permet d'avoir une idée de la quantité de travail mais
        ce n'est pas grave si elle n'est pas exacte. En effet, votre Tilliste pourra réajuster
        cette estimation lors de votre rdv. Vous pourrez par exemple ajouter d'autres vêtements
        à retoucher. Vous ne savez vraiment pas quoi choisir ? Pas de problème, vous pouvez aussi
        passer cette étape en choisissant la réservation en 3 clics !
        `,
      },
      {
        question: 'Pourquoi dois-je préciser mes matières durant ma prise de commande ?',
        answer: `Tous nos Tillistes ont des spécialités différentes ! Il est important pour
        nous de trouver la bonne expertise en fonction de votre besoin, afin de vous procurer
        le meilleur service de retouche possible.
        `,
      },
      {
        question: 'Dois-je fournir du matériel, par exemple un zip ?',
        answer: `Nos experts fournissent les basiques (boutons, élastiques, doublures, zips,
        thermocollants) dans les coloris classiques. Pour toutes les demandes avec une forme ou
        un coloris particulier, nous vous demandons de vous les procurer avant votre rendez-vous.
        `,
      },
      {
        question: 'Si je commande à nouveau des retouches, puis-je avoir la même Tilliste que la fois précédente ?',
        answer: `Oui évidemment, nous vous assignerons par défaut la même Tilliste
        sous réserve qu’elle soit disponible à ce moment là.
        `,
      },
      {
        question: 'Est-il possible de commander pour une retouche express ?',
        answer: `Nous essayons de répondre aux mieux aux demandes exceptionnelles
        selon la disponibilité de nos Tillistes. N'hésitez pas à nous contacter à contact@tilli.fr
        `,
      },
    ],
  },
  {
    name: 'Votre rendez-vous avec votre Tilliste',
    questions: [
      {
        question: 'Si j‘ai un empêchement, puis-je décaler mon rendez-vous ?',
        answer: `Oui, vous pouvez décaler votre rendez-vous avec votre Tilliste sous réserve
        que l'annulation soit faite au minimum 8h avant l'heure initiale du rendez-vous. Entre 8h
        et 2h avant, la somme de 14€ vous sera facturée, et moins de 2h avant ou pendant le
        rendez-vous, la somme de 25€ vous sera facturée.
        `,
      },
      {
        question: 'Les retouches se font-elles sur place ?',
        answer: `Seules les retouches de rideaux très épais ou difficilement décrochables
        sont effectuées sur place,
        les retouches de voilages et des vêtements ne sont pas faites sur place mais dans
        l‘atelier de votre Tilliste. Pour toute demande urgente nécessitant un travail à
        effectuer sur place, merci de nous envoyer un mail à contact@tilli.fr.
        Nous essayons de répondre aux mieux aux demandes exceptionnelles selon la
        disponibilité de nos Tillistes.
        `,
      },
      {
        question: 'Ai-je des choses à préparer avant mon rendez-vous ?',
        answer: `Si votre commande porte sur des retouches de vêtements, vous n'avez rien
        à préparer avant la venue de votre Tilliste, sauf les vêtements à retoucher. Si votre
        commande porte sur des retouches de rideaux, il est très important de les avoir repassés,
        installés puis laissés pendre pendant 48H, pour s'assurer qu'ils ne se détendent pas après
        la retouche.
        `,
      },
    ],
  },
  {
    name: 'Paiement et SAV',
    questions: [
      {
        question: 'Suis-je débité au moment de la commande ?',
        answer: `Non, lors de votre réservation en ligne, nous vérifions uniquement que votre
        carte est valide pour assurer le déplacement de nos Tillistes à votre domicile.
        `,
      },
      {
        question: 'Quand suis-je débité ?',
        answer: `Vous êtes débité seulement après le rdv avec votre Tilliste.
        Puisque vous pouvez toujours ajouter des vêtements ou des retouches lors du rdv,
        c‘est après ce rdv que l‘on connaît le montant exact de la commande.
        `,
      },
      {
        question: 'En cas de soucis avec mes retouches, que se passe-t-il ?',
        answer: `Toutes nos retouches sont assurées par AXA. Nous ferons le nécessaire pour
        régler le problème.
        `,
      },
    ],
  },
];

const FAQ = () => (
  <Container>
    <Title1>Les questions les plus fréquentes</Title1>
    <Column>
      <ImgCover src={photoMan} alt="Retouche à Paris de qualité" />
      <Dots center />
      {questionsSections.map(questionsSection => (
        <div key={questionsSection.name}>
          <Title2>{questionsSection.name}</Title2>
          {questionsSection.questions.map(question => (
            <div key={question.question}>
              <Row>
                <Icon src={iconQuestion} alt="Retouche à Paris" />
                <Title3>{question.question}</Title3>
              </Row>
              {question.answers && question.answers.map(answer =>
                <P>{answer}</P>,
              )}
              {question.answer &&
                <P>{question.answer}</P>
              }
              <Dots center />
            </div>
          ))}
        </div>
      ))}
      <ImgCover src={Assurance3} alt="Retouche de parka, manteau, Tilly" />
      <CallToAction>Pour toute autre question, vous pouvez nous contacter par mail : contact@tilli.fr</CallToAction>
      <ButtonContainer>
        <Link to={routesMap.OrderPage.url}>
          <Button>{'passer commande'}</Button>
        </Link>
      </ButtonContainer>
    </Column>
    <BannerContainer><Banner slowAnim /></BannerContainer>
  </Container>
);

export default FAQ;
